<template>
  <div class="summary-current-month-container">
    <b-card class="summary-current-month-card">
      <div class="row-style">
        <d-body-nav-bar
            :tabs="[]"
            position="float-right"
            title="views.payment.content.custom-infos.summary-current-month.title"
        />
      </div>
      <b-row no-gutters>
        <b-col class="mt-5" :cols="getChartPieCols()">
            <pie/>
        </b-col>
        <b-col :cols="getLegendCols ()" class="pl-0 pr-0">
          <b-row align="left" class="mt-5">
            <span class="chart-summary-label">
              <i class="chart-summary-online fa fa-circle"></i>
              {{ $t('views.payment.content.custom-infos.summary-current-month.online-payment') }}
            </span>
          </b-row>
          <b-row align="center">
              <span class="inactive-tree">
              <i class="fa fa-circle text-white"></i>
              978,90 €
            </span>
          </b-row>
          <b-row align="left" class="mt-5">
            <span class="chart-summary-label">
              <i class="chart-summary-sepa fa fa-circle"></i>
              {{ $t('views.payment.content.custom-infos.summary-current-month.withdrawals-payment') }}
            </span>
          </b-row>
          <b-row align="center">
              <span class="inactive-tree">
              <i class="fa fa-circle text-white"></i>
              1,452,90 €
            </span>
          </b-row>
          <b-row></b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
export default{
    components: {
      Pie: () => import('./charts/Pie'),
    },
    methods: {
      getChartPieCols () {
        const width = this.$store.getters["layout/getInnerWidth"];

        if (width < 1185 && width >= 920) {
          return 5;
        } else if (width < 766) {
          return 8;
        } else {
          return 7
        }
      },
      getLegendCols () {
        const width = this.$store.getters["layout/getInnerWidth"];

        if (width < 766) {
          return 4;
        } else {
          return 5
        }
      }
    }
}
</script>
<style scoped>
.summary-current-month-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 320px;
  max-height: 320px;
  padding: 1.25rem;
}

.summary-current-month-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}
</style>